<template>
  <div class="contaner-warehouse-order">
    <CRow>
      <h4 class="font-size-16 font-weight-bold mb-3 mt-2">
        Потребность в материалах
      </h4>
    </CRow>

    <CRow class="mb-4">
      <table class="table">
        <thead class="text-13" >
          <th>№</th>
          <th>Наименование</th>
          <th>Версия</th>
          <th>Код</th>
          <th class="text-right">Кол-во</th>
          <th class="text-right">Цена</th>
          <th class="text-right">Стоимость</th>
        </thead>

        <tbody>
          <tr class="text-13"
            v-for="(equipmentDetail, index) in equipmentTotalDetails"
            :key="equipmentDetail.id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ equipmentDetail.description }}</td>
            <td v-if="equipmentDetail.version">v.{{ equipmentDetail.version }}</td>
            <td v-else></td>
            <td>{{ equipmentDetail.code }} </td>
            <td class="text-right">{{ equipmentDetail.amount }}</td>
            <td class="text-right">{{ (Math.round((parseFloat(equipmentDetail.price) + Number.EPSILON) * 100) / 100).toString().replace('.', ',') }}</td>
            <td class="text-right">{{ (Math.round((equipmentDetail.cost + Number.EPSILON) * 100) / 100).toString().replace('.', ',') }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right">Итого</td>
            <td class="text-right">{{ (Math.round((totalCost + Number.EPSILON) * 100) / 100).toString().replace('.', ',') }}</td>
          </tr>
        </tbody>
      </table>
    </CRow>
  </div>
</template>


<script>
import { moneyFormat } from "../utils.js";

export default {
  name: "EquipmentItemParts",
  props: {
    partsMap: Object,
    equipmentParts: Array,
    equipmentPartsByItem: Array,
    equipmentDetails: Array,
    equipmentPackages: Array,
  },
  data() {
    return {
      equipmentTotalDetails: [],
      totalCost: 0,
    };
  },
  computed: {},
  created() {
    const equipmentTotalDetails = {};
    for (const testEquipmentPart of this.equipmentPartsByItem) {
      const equipmentPart = this.getEquipmentPartByCode(testEquipmentPart.code, testEquipmentPart.version);
      if (equipmentPart) {
        for (const equipmentDetail of equipmentPart.equipment_details) {
          
          const equipmentDetailMeta = this.getEquipmentDetailById(
            equipmentDetail.equipment_detail_id
          );
          if (!equipmentTotalDetails[equipmentDetailMeta.code]) {
            equipmentTotalDetails[equipmentDetailMeta.code] = {
              code: equipmentDetailMeta.code,
              version: equipmentDetailMeta.version,
              description: equipmentDetailMeta.description,
              price: equipmentDetailMeta.price,
              amount: 0,
            };
          }
          equipmentTotalDetails[equipmentDetailMeta.code].amount +=
            equipmentDetail.amount;
        }
      }
    }
    this.equipmentTotalDetails = [];
    for (const code in equipmentTotalDetails) {
      equipmentTotalDetails[code].cost =
        equipmentTotalDetails[code].price * equipmentTotalDetails[code].amount;
      this.equipmentTotalDetails.push(equipmentTotalDetails[code]);
      this.totalCost += equipmentTotalDetails[code].cost;
    }
    this.equipmentTotalDetails.sort(this.compareEquipmentDetails)
    for (const equipmentPackage of this.equipmentPackages) {
      this.equipmentTotalDetails.push({
        id: `package_${equipmentPackage.id}`,
        code: equipmentPackage.code,
        version: equipmentPackage.version,
        description: equipmentPackage.description,
        amount: this.getAmount(equipmentPackage),
        price: equipmentPackage.price,
        cost: equipmentPackage.price * equipmentPackage.amount,
      });
      this.totalCost += equipmentPackage.price * equipmentPackage.amount;
    }
  },
  methods: {
    getEquipmentPartCodeFromEquipmentPartKey(equipmentPartKey) {
      const foundEquipmentPartCodes = equipmentPartKey.rsplit("_", 1);
      const foundEquipmentPartCode =
        foundEquipmentPartCodes.length > 0 ? foundEquipmentPartCodes[0] : "";
      return foundEquipmentPartCode;
    },
    getEquipmentPartByCode(equipmentPartCode, equipmentPartVersion) {
      for (const equipmentPart of this.equipmentParts) {
        if (equipmentPart.code == equipmentPartCode && equipmentPart.version == equipmentPartVersion) {
          return equipmentPart;
        }
      }
      return null;
    },
    getEquipmentDetailById(equipmentDetailId) {
      for (const equipmentDetail of this.equipmentDetails) {
        if (equipmentDetail.id == equipmentDetailId) {
          return equipmentDetail;
        }
      }
      return null;
    },
    moneyFormat(value) {
      return moneyFormat(value);
    },
    getAmount(equipmentItem) {
      if (equipmentItem.measure === "millimeter") {
        return (
          Math.round((equipmentItem.amount / 1000 + Number.EPSILON) * 1000) /
          1000
        )
          .toString()
          .replace(".", ",");
      } else {
        return equipmentItem.amount;
      }
    },
    getMeasure(equipmentItem) {
      if (equipmentItem.measure === "millimeter") {
        return "м";
      } else {
        return "шт";
      }
    },
    compareEquipmentDetails(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
